import React from "react"
import { Link } from "gatsby"

export const PrimaryButton = ({ children, className, ...rest }) => {
  return (
    <Link
      {...rest}
      className={`inline-flex items-center justify-center px-10 py-3 text-base font-bold transition-colors duration-100 rounded-md text-blue-900 bg-white shadow hover:shadow-lg hover:bg-blue-50 ${className}`}
    >
      {children}
    </Link>
  )
}

export const SecondaryButton = ({ children, className, ...rest }) => {
  return (
    <Link
      {...rest}
      className={`inline-flex items-center justify-center px-8 py-3 text-xs font-bold transition-colors duration-100 text-blue-700 border-solid border bg-white rounded-md lg:text-sm group hover:bg-blue-50 ${className}`}
    >
      {children}
    </Link>
  )
}
